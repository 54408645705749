<template>
  <div></div>
</template>
<script>
import {General} from "../store/url";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "LogoutItem",
  mixins: [Mixin],
  props: {},
  components: {},
  data() {
    return {
      module: General,
    };
  },
  created() {
    let self = this;
    axios
        .post(self.logoutUrlApi(self.module), self.model)
        .then(function (response) {
          if (response.status === 200) {
            localStorage.removeItem("email");
            localStorage.removeItem("userId");
            localStorage.removeItem("token");
            localStorage.setItem("isLogged", false);
            self.$router.push("/signin");
            localStorage.removeItem("email");
            localStorage.removeItem("userId");
          }
        })
        .catch(function () {
          self.$router.push("/signin");
        });
  },
  computed: {},
  methods: {},
};
</script>


